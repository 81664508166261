<template>
  <v-container>
    <v-card height="100%" dark flat color="transparent">
      <v-data-iterator :items="$Posts.filter((Element) => Element.categories[0] == 7)" sort-by="acf.order"
        :sort-desc="false" :items-per-page="$Posts.filter((Element) => Element.categories[0] == 3).length"
        hide-default-footer>
        <template v-slot:default="props">
          <v-row class="my-10" justify="center" no-gutters v-for="(Post, Index) in props.items" :key="Index">
            <v-col cols="12" xs="12" sm="12" md="12" color="white">
              <div class="text-end mb-2">
                <v-chip class="px-5">
                  <v-icon small class="mr-2"> mdi-calendar-month </v-icon>
                  {{ Post.acf.date }}
                </v-chip>
              </div>
              <h2 class="ZeroSpace mb-3">{{ Post.title.rendered }}</h2>
              <v-chip small class="px-3 mr-2 mt-1" :color="Post.acf.link1 == '' ? 'error' : 'success'">
                <v-icon small class="mr-2"> {{Post.acf.link1 == '' ? 'mdi-wifi-off' :
                'mdi-checkbox-marked-circle-outline'}} </v-icon>
                {{Post.acf.link1 == '' ? 'Offline' : 'Online'}}
              </v-chip>
              <v-chip v-for="Index in 3" :key="Index" v-show="Post.acf['link' + Index] != ''"
                :href="Post.acf['link' + Index].url" :target="Post.acf['link' + Index].url" small class="px-3 mr-2 mt-1"
                :color="$Settings.primary_color">
                <v-icon small class="mr-2"> mdi-link-variant </v-icon>
                {{ Post.acf['link' + Index].title }}
              </v-chip>
            </v-col>
            <v-col cols="12" class="rounded-lg my-3" style="overflow:hidden;">
              <span class="ma-4" style="position:absolute;z-index:1 !important;width:125px;height: 125px;">
                <v-img width="90%" :src="Post.acf.logo.url + '?v=' + new Date().getTime()" contain />
              </span>
              <div v-html="Post.content.rendered" />
            </v-col>
            <v-col cols="12">
              <h2 class="ZeroSpace">
                <v-chip small class="mb-2 mr-2 py-3" v-for="(Item, Index) in Post.acf.description.split(',')"
                  :color="Item.split(':')[0]!='E' ? 'primary' :'warning'" :key="Index">
                  <v-icon small class="mr-2"> mdi-checkbox-marked-circle-outline </v-icon>
                  {{ Item.split(':')[0]!='E' ? Item : Item.split(':')[1] }}
                </v-chip>
              </h2>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'Portfolio-Page',
  props: ['settings'],
  data: () => ({}),
  mounted () { },
};
</script>
