import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import Axios from 'axios'

Vue.config.productionTip = false
Vue.prototype.$http = Axios;
Vue.prototype.$http.get('https://xyz.anasalhakim.com/wp-json/wp/v2/pages/?slug=settings').then((Settings) => {
  Vue.prototype.$http.get('https://xyz.anasalhakim.com/wp-json/wp/v2/posts?per_page=100').then((Posts) => {
    Vue.prototype.$Settings = Settings.data[0].acf;
    Vue.prototype.$Posts = Posts.data;

    console.log(Vue.prototype.$Settings);
    console.log(Vue.prototype.$Posts)
    new Vue({
      vuetify,
      router,
      render: h => h(App)
    }).$mount('#app')
  })
});

