<template>
  <v-app style="background-color: black;">
    <transition name="slide-top" mode="out-in">
      <v-progress-circular v-if="!App.Loaded" indeterminate color="white" size="100" class="position-fixed"
        style="top: 50%; left: 50%; transform: translate(-50%, -50%);">
      </v-progress-circular>
      <Home v-else />
    </transition>
  </v-app>
</template>

<script>
import Home from './views/Home.vue';
export default {
  name: 'App',
  data: () => ({
    App: {
      Loaded: false
    }
  }),
  components: { Home },
  mounted () {
    setTimeout(() => {
      this.App.Loaded = true;
    }, 1000)
  },
};
</script>
<style>
@font-face {
  font-family: "Cantarell";
  font-style: normal;
  font-weight: 400;
  src: local("Cantarell"), url("https://www.anasalhakim.com/Cantarell-Regular.ttf") format("truetype");
}

* {
  font-family: 'Cantarell' !important;
  font-weight: 600 !important;
}

html {
  overflow-y: auto;
}

body {
  background-color: black;
}

.ZeroSpace {
  margin: 0 !important;
  padding: 0 !important;
}

.BackgroundImage {
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

::-webkit-scrollbar {
  width: 0px;
  background: darkgrey;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px lightgray;
}

::-webkit-scrollbar-thumb {
  background: gray;
}

.LinksStyle {
  line-height: 2rem;
}

.LinksStyle a {
  color: white !important;
  text-decoration: none;
  padding-bottom: 3px;
  border-bottom: 1px solid white;
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active,
.slide-top-enter-active,
.slide-top-leave-active {
  transition-duration: 0.8s;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active,
.slide-top-enter-active,
.slide-top-leave-active {
  transition-duration: 0.8s;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  transform: translate(2em, 0);
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  transform: translate(-2em, 0);
}

.slide-top-leave-active,
.slide-top-enter {
  opacity: 0;
  transform: translate(0, 2em);
}
</style>