<template>
  <v-container>
    <v-card height="100%" dark flat color="transparent"
      v-for="(Post, Index) in $Posts.filter((Element) => Element.categories[0] == 9)" :key="Index">
      <v-row class="my-5" justify="start" align="start" no-gutters>
        <v-col cols="12">
          <v-img width="100%" height="100%" class="rounded-lg" :src="Post.acf.logo.url + '?v=' + new Date().getTime()"
            contain>
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-col>
        <v-col cols="12" color="white">
          <div class="text-end mb-2">
            <v-chip class="px-5">
              <v-icon small class="mr-2"> mdi-calendar-month </v-icon>
              Updated : {{ Post.acf.date }}
            </v-chip>
          </div>
          <div v-html="Post.content.rendered" class="LinksStyle"></div>
          <v-chip v-for="Index in 3" :key="Index" v-show="Post.acf['link' + Index] != ''"
            :href="Post.acf['link' + Index].url" :target="Post.acf['link' + Index].url" small class="px-3 mr-2 mt-1"
            :color="$Settings.primary_color">
            <v-icon small class="mr-2"> mdi-link-variant </v-icon>
            {{ Post.acf['link' + Index].title }}
          </v-chip>
        </v-col>
      </v-row>
      <v-divider />
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'Privacy-Page',
  props: ['settings'],
  data: () => ({}),
  mounted () { },
};
</script>
