<template>
  <v-container>
    <v-card dark flat color="transparent">
      <v-card-title class="ZeroSpace display-1">
        <v-icon large class="mr-3"> {{ settings.Icon }}</v-icon>
        {{ settings.Title }}
      </v-card-title>
    </v-card>
    <v-divider color="white" class="mt-4" />
  </v-container>
</template>

<script>
export default {
  name: 'Title-Component',
  props: ['settings'],
  data: () => ({}),
  mounted () { },
};
</script>
