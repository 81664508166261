<template>
  <v-container>
    <v-card height="100%" dark flat color="transparent">
      <v-data-iterator :items="$Posts.filter((Element) => Element.categories[0] == 4)" sort-by="acf.order" :sort-desc="false" :items-per-page="$Posts.filter((Element) => Element.categories[0] == 3).length" hide-default-footer>
        <template v-slot:default="props">
          <v-row class="my-10" justify="center" no-gutters v-for="(Post, Index) in props.items" :key="Index">
            <v-col cols="12" lass="px-5 text-center">
              <iframe width="100%" class="rounded-lg" height="400" :src="Post.acf.link1.url" :title="Post.acf.link1.title" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </v-col>
            <v-col cols="12" class="pt-2" color="white">
              <div class="text-end mb-2">
                <v-chip class="px-5">
                  <v-icon small class="mr-2"> mdi-calendar-month </v-icon>
                  {{ Post.acf.date }}
                </v-chip>
              </div>
              <h2 class="ZeroSpace">{{ Post.title.rendered }}</h2>
              <h4 class="font-weight-light my-2">{{ Post.acf.description }}</h4>
              <v-chip v-for="Index in 2" :key="Index" v-show="Post.acf['link' + Number(Index + 1)] != ''" :href="Post.acf['link' + Number(Index + 1)].url" :target="Post.acf['link' + Number(Index + 1)].url" small class="px-3 mr-2 mt-1" :color="$Settings.primary_color">
                <v-icon small class="mr-2"> mdi-link-variant </v-icon>
                {{ Post.acf['link' + Number(Index + 1)].title }}
              </v-chip>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'Media-Page',
  props: ['settings'],
  data: () => ({}),
  mounted() {},
};
</script>
