<template>
  <v-container>
    <v-card height="100%" dark flat color="transparent">
      <v-data-iterator :items="$Posts.filter((Element) => Element.categories[0] == 3)" sort-by="acf.order"
        :sort-desc="false" :items-per-page="$Posts.filter((Element) => Element.categories[0] == 3).length"
        hide-default-footer>
        <template v-slot:default="props">
          <v-row class="my-10" justify="center" no-gutters v-for="(Post, Index) in props.items" :key="Index">
            <v-col cols="12" xs="12" sm="12" md="2" class="white text-center rounded-lg">
              <v-img width="90%" height="100%" class="mx-auto py-2"
                :src="Post.acf.logo.url + '?v=' + new Date().getTime()" contain />
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="10" class="px-5" color="white">
              <div class="text-end mb-2">
                <v-chip class="px-5">
                  <v-icon small class="mr-2"> mdi-calendar-month </v-icon>
                  {{ Post.acf.date }}
                </v-chip>
              </div>
              <h2 class="ZeroSpace">{{ Post.title.rendered }}</h2>
              <h4 class="font-weight-light my-2">{{ Post.acf.description }}</h4>
              <v-chip v-for="Index in 3" :key="Index" v-show="Post.acf['link' + Index] != ''"
                :href="Post.acf['link' + Index].url" :target="Post.acf['link' + Index].url" small class="px-3 mr-2 mt-1"
                :color="$Settings.primary_color">
                <v-icon small class="mr-2"> mdi-link-variant </v-icon>
                {{ Post.acf['link' + Index].title }}
              </v-chip>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'Press-Page',
  props: ['settings'],
  data: () => ({}),
  mounted () { },
};
</script>
