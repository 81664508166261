<template>
  <v-container fluid class="ZeroSpace fill-height">
    <v-dialog v-model="Dialog.Open" :width="$vuetify.breakpoint.name == 'xs' ? '90vw' : '40vw'" class="pa-10" scrollable>
      <v-card dark flat color="rgb(0,0,0,0.9)" rounded="xl">
        <v-card-title>
          <Title :settings="{ Icon: Dialog.Icon, Title: Dialog.Type }" />
        </v-card-title>
        <v-card-text>
          <Press v-if="Dialog.Type == 'Press'" :settings="{ Title: Dialog.Type, Icon: Dialog.Icon }" />
          <Media v-if="Dialog.Type == 'Media'" :settings="{ Title: Dialog.Type, Icon: Dialog.Icon }" />
          <Biography v-if="Dialog.Type == 'Biography'" :settings="{ Title: Dialog.Type, Icon: Dialog.Icon }" />
          <Portfolio v-if="Dialog.Type == 'Portfolio'" :settings="{ Title: Dialog.Type, Icon: Dialog.Icon }" />
          <Imprint v-if="Dialog.Type == 'Imprint'" :settings="{ Title: Dialog.Type, Icon: Dialog.Icon }" />
          <Privacy v-if="Dialog.Type == 'Privacy'" :settings="{ Title: Dialog.Type, Icon: Dialog.Icon }" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-row align-content="center" class="BackgroundImage fill-height"
      :style="'background-image: url(' + this.$Settings.backgroundimage.url + ')'">
      <v-col class="text-center">
        <v-card width="60vw" class="mx-auto" dark color="transparent" flat>
          <v-divider color="white" class="mb-4" />
          <v-card-title class="headline justify-center"> ANAS ALHAKIM </v-card-title>
          <v-card-subtitle class="white--text mt-2">
            <h3 class="font-weight-bold mb-2">Full Stack Developer</h3>
            <h4 class="font-weight-regular">BERLIN, GERMANY</h4>
          </v-card-subtitle>
          <v-card-text class="white--text mt-4">
            <h4 class="font-weight-bold">CONTACTS</h4>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn link fab outlined dark v-for="(Link, Index) in SocialMedia" class="mx-3" :key="Index" :href="Link.Url"
              target="_blank">
              <v-icon>{{ Link.Icon }}</v-icon>
            </v-btn>
          </v-card-actions>
          <v-divider color="white" class="my-4" />
          <v-card-actions class="justify-center">
            <v-row dense>
              <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" v-for="(Section, Index) in Sections" :key="Index">
                <v-btn class="mx-1" height="50" block outlined rounded dark
                  @click="[(Dialog.Open = true), (Dialog.Type = Section.Type), (Dialog.Icon = Section.Icon)]">
                  <v-icon>{{ Section.Icon }}</v-icon>
                  <span class="px-5">{{ Section.Type }}</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-footer fixed class="justify-center pb-3" dark color="transparent">
      <v-card flat color="transparent">
        <v-card-subtitle class="text-center ZeroSpace headline">
          <v-btn link small rounded class="mb-2" color="transparent"
            @click="[(Dialog.Open = true), (Dialog.Type = 'Imprint'), (Dialog.Icon = 'mdi-lock')]">Imprint
            (German)</v-btn>
          <v-btn link small rounded class="mb-2" color="transparent"
            @click="[(Dialog.Open = true), (Dialog.Type = 'Privacy'), (Dialog.Icon = 'mdi-lock')]">Privacy
            (German)</v-btn>
        </v-card-subtitle>
        <v-card-title class="justify-center ZeroSpace">
          Anas Alhakim © {{ new Date().getFullYear() }}
        </v-card-title>
      </v-card>
    </v-footer>
  </v-container>
</template>

<script>
import Title from '../components/Title';
import Biography from '../components/Biography';
import Media from '../components/Media';
import Press from '../components/Press';
import Portfolio from '../components/Portfolio';
import Privacy from '../components/Privacy.vue';
import Imprint from '../components/Imprint.vue';

export default {
  name: 'Home-Page',
  components: { Biography, Media, Press, Title, Portfolio, Privacy, Imprint },
  data: () => ({
    SocialMedia: [],
    Sections: [],
    Dialog: {
      Open: false,
      Type: '',
    },
  }),
  mounted () {
    this.SocialMedia = [
      {
        Icon: 'mdi-facebook',
        Url: 'https://www.facebook.com/anas.alhakim',
      },
      {
        Icon: 'mdi-instagram',
        Url: 'https://www.instagram.com/alhakim.anas',
      },
      {
        Icon: 'mdi-linkedin',
        Url: 'https://www.linkedin.com/in/anasalhakim',
      },
      {
        Icon: 'mdi-email-fast',
        Url: 'mailto:anas@anasalhakim.com',
      },
    ];
    this.Sections = [
      {
        Type: 'Biography',
        Icon: 'mdi-account-circle',
      },
      {
        Type: 'Media',
        Icon: 'mdi-television-classic',
      },
      {
        Type: 'Portfolio',
        Icon: 'mdi-palette',
      },
      {
        Type: 'Press',
        Icon: 'mdi-pen',
      },
    ];
  },
};
</script>
